import { createApp } from 'vue'
import App from './App.vue'
import cookies from './plugins/cookies'
import i18n from './i18n'
import loadAnalyticsScripts from '@/services/analytics'
import router from './router'
import { setLocale } from './utils'
import pusher from './plugins/pusher'
import store from './store'
import FloatingVue from 'floating-vue'
import { FocusTrap } from 'focus-trap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Sentry from '@sentry/vue'
import { ClientTable } from 'v-tables-3';
import { defineRule } from 'vee-validate'
import { required, email, url } from '@vee-validate/rules'
import vSelect from 'vue-select'
import VueCookies from 'vue-cookies'
import { Vue3Mq } from 'vue3-mq'
import VueSmoothScroll from 'v-smooth-scroll'
import 'floating-vue/dist/style.css'
import './plugins/vee-validate'
import 'vue-select/dist/vue-select.css'
import {
    faArrowUp,
    faBars,
    faBullhorn,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faInfoCircle,
    faSearch,
    faTimes,
    faWifi
} from '@fortawesome/free-solid-svg-icons'

import Columns from '@/views/hub/components/layout/Columns.vue'
import Rows from '@/views/hub/components/layout/Rows.vue'

library.add(faArrowUp)
library.add(faBars)
library.add(faBullhorn)
library.add(faChevronDown)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faChevronUp)
library.add(faInfoCircle)
library.add(faSearch)
library.add(faTimes)
library.add(faWifi)

defineRule('required', required);
defineRule('email', email);
defineRule('url', url);

(async () => {
    let authAttempt = false
    let setupError
    let config
    try {
        localStorage.removeItem('base-url')
        localStorage.removeItem('auth-fail-count')
        config = await store.dispatch('fetchConfig')
        const cookies = VueCookies
        if (!cookies.get('timezone')) {
            cookies.set('timezone', config.time_zone_name);
        }

        setLocale(config.languages);

        window.addEventListener('offline', () => store.dispatch('setOffline', true));
        window.addEventListener('online', () => {
            store.dispatch('setOffline', false)
            store.dispatch('refresh')
        });

        const { userAgent, platform } = window.navigator
        const iosPlatforms = ['iPhone', 'iPad', 'iPod']
        const isMobileDevice = (/Android/.test(userAgent) || iosPlatforms.indexOf(platform) !== -1)

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible' && isMobileDevice) {
                store.dispatch('refresh')
            }
        })
    } catch (error) {
        if (String(error) === 'Error: unable-to-authorize') {
            await store.dispatch('setAuthFail')
        } else if (String(error) === 'Error: authorization-required') {
            authAttempt = true
        } else if (!error.response) {
            setupError = error
        } else if (error.response?.status === 404) {
            await store.dispatch('set404')
        } else if (error.response?.status === 401) {
            authAttempt = true
        } else {
            setupError = error
        }
    }
    if (!authAttempt) {
        const app = createApp(App)
        Sentry.init({
            app,
            denyUrls: [
                '127.0.0.1',
                'localhost',
                /local-.*\.statushub-dev\.com/
            ],
            dsn: import.meta.env.VITE_APP_SENTRY_DSN,
            release: import.meta.env.VITE_APP_VERSION
        })
        if (setupError !== undefined) {
            Sentry.captureException(setupError)
        }
        if (import.meta.env.NODE_ENV !== 'test') {
            app.use(router)
        }
        app
            .use(cookies)
            .use(i18n)
            .use(store)
            .use(ClientTable)
            .use(FloatingVue, {
                themes: {
                    'info-tooltip': {
                        $extend: 'tooltip',
                        $resetCss: true,
                    }
                }
            })
            .use(Vue3Mq)
            .use(VueSmoothScroll)
            .component('Columns', Columns)
            .component('Rows', Rows)
        if (config?.pusher) {
            app.use(pusher, {
                appKey: config.pusher.app_key,
                cluster: config.pusher.cluster,
                event: `status_page_${config.subdomain}`,
                channel: `status_page_${config.subdomain}`,
                router
            })
        }
        app.component('FocusTrap', FocusTrap)
            .component('font-awesome-icon', FontAwesomeIcon)
            .component('v-select', vSelect)
        if (config?.analytics) {
            loadAnalyticsScripts(app, config.analytics)
        }
        app.mount('#app')
    }
})()
