<script>
import StatusDot from '@/components/StatusDot.vue'
import Icon from '@/components/Icon.vue'
import TextExpander from '@/components/TextExpander.vue'
import EventCardServices from '@/components/EventCardServices.vue'
import timeOperations from '@/mixins/timeOperations.js'
import incidentMixin from '@/mixins/incident.js'
import { mapGetters } from 'vuex'

export default {
    name: 'EventCard',
    mixins: [
        incidentMixin,
        timeOperations
    ],
    props: {
        event: {
            type: Object,
            required: true
        },
        type: {
            type: Number,
            default: undefined
        },
        count: {
            type: Number,
            default: 1
        }
    },
    components: {
        EventCardServices,
        Icon,
        StatusDot,
        TextExpander
    },
    data () {
        return {
            isSmallSizeMessageExpanded: false
        }
    },
    computed: {
        ...mapGetters({
            config: 'config'
        }),
        eventData () {
            if (this.isIncident) {
                return {
                    ...this.event,
                    ...this.event.updates ? this.event.updates[0] : {}
                }
            } else {
                return this.event
            }
        },
        isLargeSize () {
            return this.count === 1
        },
        isMediumSize () {
            return this.count > 1 && this.count < 3
        },
        isSmallSize () {
            return this.count >= 3
        },
        linesVisibleWithoutExpanding () {
            if (this.isLargeSize) return 8
            else if (this.isMediumSize) return 4
            else return 0
        },
        isIncident () {
            return this.type === 0 || this.event.event_type === 0
        },
        isMaintenance () {
            return this.type === 1 || this.event.event_type === 1
        },
        eventType () {
            return this.isIncident ? 'incident' : 'maintenance'
        },
        eventIconName () {
            return this.isIncident
                ? this.incidentIconName(this.eventData)
                : 'maintenance'
        },
        eventTitle () {
            return this.isIncident
                ? this.incidentTitle(this.eventData)
                : this.event.title
        },
        isStatusCountersView () {
            return this.config.event_services_list_mode === 0
        },
        isServicesListView () {
            return this.config.event_services_list_mode !== 0
        }
    },
    methods: {
        toggleSmallSizeMessageExpanded () {
            this.isSmallSizeMessageExpanded = !this.isSmallSizeMessageExpanded
        }
    }
}
</script>

<template>
    <li
        class="event-card"
        :class="{
            'event-card--l': isLargeSize,
            'event-card--m': isMediumSize,
            'event-card--s': isSmallSize,
            'event-card--collapsed': !isSmallSizeMessageExpanded
        }"
    >
        <header class="event-card__header">
            <div class="event-card__header__title">
                <Icon :name="eventIconName" />
                <router-link
                    :to="{
                        name: eventType,
                        params: { id: event.id }
                    }"
                    target="_blank"
                >
                    {{ eventTitle }}
                </router-link>
            </div>
            <Icon
                v-if="isSmallSize && eventData.message"
                :name="isSmallSizeMessageExpanded ? 'chevron-up' : 'chevron-down'"
                class="event-card__header__toggle-btn"
                @click.native="toggleSmallSizeMessageExpanded"
            />
        </header>
        <TextExpander
            v-if="eventData.message && linesVisibleWithoutExpanding"
            :text="eventData.message"
            :visible-lines="linesVisibleWithoutExpanding"
            :id="event.id"
            class="event-card__body"
        />
        <p
            v-if="isSmallSize && isSmallSizeMessageExpanded"
            v-html="eventData.message"
            class="event-card__body"
        />
        <footer
            class="event-card__footer"
            :class="{
                'event-card__footer--status-counters-view': isStatusCountersView,
                'event-card__footer--services-list-view': isServicesListView
            }"
        >
            <div class="event-card__footer__status-counters">
                <EventCardServices :services="eventData.services" />
            </div>
            <div class="event-card__footer__time">
                <p class="event-card__footer__start-time">
                    <Icon
                        v-if="isIncident"
                        :name="incidentTypeIconName(eventData)"
                    />
                    {{ formatTimestamp(event.timestamp_start) }}
                </p>
                <p v-if="isMaintenance" class="event-card__footer_end-time">
                    {{ formatTimestamp(event.timestamp_end) }}
                </p>
            </div>
        </footer>
    </li>
</template>

<style scoped lang="scss">
.event-card {
    background-color: #FFFFFF;
    border-radius: 6px;

    &:not(:last-child) {
        margin-bottom: 0.625rem;
    }

    &__header {
        background-color: #F4F7FC;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0.75rem;
        border-radius: 6px 6px 0 0;

        &__title {
            display: flex;
            align-items: center;
        }

        &__toggle-btn {
            cursor: pointer;

            :deep(svg) {
                width: 10px;
                height: 10px;
                font-size: 10px;
            }

            svg {
                width: 10px;
                height: 10px;
                font-size: 10px;
            }
        }

        .icon {
            margin-right: 1rem;
        }

        a {
            color: #0077AA;
            text-decoration: underline;
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: -0.33px;
            line-height: 1.1875rem;
        }
    }

    &__body {
        padding: 1.5rem 1.5rem 0.5rem;
        font-size: 0.8125rem;
        line-height: 1.21875rem;
        font-weight: 500;
        color: #545454;

        :deep(.text) {
            margin: 0;
        }
    }

    &__footer {
        display: grid;
        grid-template-areas: 'status-counters time';
        grid-template-columns: 1fr min-content;
        grid-gap: 1rem;
        padding: 0.25rem 1.5rem 1.25rem 1rem;

        &__time {
            height: 100%;
            color: #757575;
            font-size: 0.625rem;

            p {
                display: flex;
                align-items: center;
                margin-top: 0.25rem;
                margin-bottom: 0.25rem;
                white-space: nowrap;

                :deep(svg) {
                    transform: scale(0.5);
                }
            }
        }

        &--services-list-view {
            padding: 0.75rem 1rem;
        }
    }

    &--m {
        .event-card {
            &__body {
                padding: 1rem 1.5rem 0.5rem;
            }

            &__footer {
                padding: 0.25rem 1.5rem 0.75rem 1rem;
            }
        }
    }

    &--s {
        .event-card {
            &__header {
                padding: 1.125rem 1rem 0.75rem;
            }

            &__body {
                margin-top: 0;
                padding: 0.75rem;
            }

            &__footer {
                padding: 0.375rem 1rem 0.5rem 0.75rem;
            }
        }
    }
}

.event-card--s.event-card--collapsed {
    :deep(.event-card-services--services-list-view:not(.event-card-services--count-1)) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        .event-card-services__item {
            margin: 0;

            &__label {
                display: none;
            }

            &:not(:first-child) {
                margin: 0;
            }
        }
    }
}
</style>
